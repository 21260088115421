export default {
    "title": "🇫🇷 Français",

    "首页": "Accueil",
    "关于": "À propos",
    "会员": "Adhésion",
    "推荐计划": "Programme de parrainage",
    "个人": "Profil",
    "推荐": "Recommandation",
    "预订": "Réservations",
    "事件": "Événement",
    "登出": "Déconnexion",
    "登录": "Se connecter",
    "注册": "S'inscrire",
    "我们的应用程序现在可用": "Notre application est maintenant disponible",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Pour une meilleure expérience, nous venons de lancer nos applications mobiles pour des réservations plus fluides et plus rapides.",
    "iOS版下载（即将推出）": "Télécharger pour iOS (Bientôt disponible)",
    "Android版下载（即将推出）": "Télécharger pour Android (Bientôt disponible)",
    "为什么选择我们": "Pourquoi nous choisir",
    "我们卓越的服务。": "Nos services exceptionnels.",
    "收入最高的酒店": "Hôtels avec les meilleurs revenus",
    "我们的合作酒店收入最高。": "Nos hôtels partenaires avec les revenus les plus élevés.",
    "回报": "Retours",
    "最具特色的酒店": "Les hôtels les plus en vue",
    "我们平台上最有特色的酒店。": "Les hôtels les plus en vue sur notre plateforme.",
    "热门国家和目的地": "Pays et destinations populaires",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Découvrez quelques-uns des meilleurs conseils de la ville de nos partenaires et amis.",
    "酒店": "Hôtels",
    "预订酒店并开始赚取": "Réservez un hôtel et commencez à gagner",
    "让我们开始与TitanTraveler一起探索世界": "Commençons à explorer le monde ensemble avec TitanTraveler",
    "客户评价": "Témoignages",
    "查看我们用户的真实评价。": "Consultez nos témoignages authentiques de nos utilisateurs.",
    "银行卡列表": "Liste des cartes bancaires",
    "银行列表": "Liste des banques",
    "持卡人姓名": "Nom du titulaire de la carte",
    "银行卡号": "Numéro de compte bancaire",
    "支付密码": "Mot de passe de paiement",
    "提交": "Soumettre",
    "Pix列表": "Liste Pix",
    "Pix类型": "Type de Pix",
    "Pix账号": "Compte Pix",
    "账号列表": "Liste des comptes",
    "网络": "Réseau",
    "地址": "Adresse",
    "备注": "Remarque",
    "充值金额": "Montant de la recharge",
    "支付类型": "Type de paiement",
    "支付银行": "Banque de paiement",
    "USDT地址": "Adresse USDT",
    "二维码": "Code QR",
    "充值渠道": "Canal de recharge",
    "充值凭证": "Justificatif de recharge",
    "立即登录": "Connectez-vous maintenant",
    "用户名": "Nom d'utilisateur",
    "密码": "Mot de passe",
    "邀请码": "Code d'invitation",
    "确认密码": "Confirmer le mot de passe",
    "手机": "Mobile",
    "注册2": "Inscription",
    "金额": "Montant",
    "新增": "Nouveau",
    "我们的精彩": "Notre incroyable",
    "故事": "Histoire",
    "奖项": "Récompenses",
    "获得": "Gagné",
    "钱包余额": "Solde du portefeuille",
    "今日奖励": "Récompenses du jour",
    "总行程": "Voyage total",
    "访问行程": "Voyages visités",
    "酒店历史": "Historique des hôtels",
    "单价": "Prix",
    "数量": "Nombre",
    "总额": "Total",
    "奖励": "Récompenses",
    "上一页": "Précédent",
    "下一页": "Suivant",
    "每套预订量": "Réservations par lot",
    "预订奖励": "Récompense de réservation",
    "每日预订": "Réservations quotidiennes",
    "手机号码": "Numéro de téléphone",
    "修改密码": "Changer le mot de passe",
    "登录密码": "Mot de passe de connexion",
    "当前密码": "Mot de passe actuel",
    "新密码": "Nouveau mot de passe",
    "确认新密码": "Confirmer le nouveau mot de passe",
    "保存修改": "Enregistrer les modifications",
    "充值": "Recharger",
    "我们的": "Notre",
    "复制": "Copier",
    "分享链接": "Partager le lien",
    "搜索酒店": "Rechercher un hôtel",
    "实际等级": "Niveau réel",
    "升级": "Améliorer",
    "已完成预订": "Réservations terminées",
    "登录时间": "Heure de connexion",
    "信誉分": "Crédit",
    "控制菜单": "Menu du tableau de bord",
    "提现": "Retirer",
    "提现账号": "Compte de retrait",
    "你确定要删除此账号吗": "Êtes-vous sûr de vouloir supprimer ce compte?",
    "你确定要删除此地址吗": "Êtes-vous sûr de vouloir supprimer cette adresse?",
    "提示": "Conseils",
    "是": "Oui",
    "否": "Non",
    "全部": "Tout",
    "待处理": "En attente",
    "冻结中": "Gelé",
    "已完成": "Terminé",
    "订阅": "S'abonner",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Vous souhaitez être averti lorsque nous lançons un nouveau modèle ou une mise à jour? Inscrivez-vous simplement et nous vous enverrons une notification par email.",
    "我们的伙伴": "Nos partenaires",
    "关于我们": "À PROPOS DE NOUS",
    "实时聊天支持": "Support en chat live",
    "在线聊天": "Chat en ligne",
    "创建时间": "Heure de création",
    "你确定要登出当前账号吗？": "Êtes-vous sûr de vouloir vous déconnecter du compte actuel?",
    "请输入你的邮箱": "Entrez votre email",
    "好评": "Très bien",
    "条评论": "Avis",
    "平均每晚": "Moy/Nuit",
    "旅行旅程": "Voyages",
    "正在寻找酒店": "Recherche d'hôtel",
    "开始预订": "Commencer la réservation",
    "旅程价格": "Prix du voyage",
    "佣金": "Commissions",
    "底部-about": "est une plateforme de programme d'affiliation qui vous donne l'opportunité unique de connecter votre entreprise à la plateforme de réservation d'hébergement en ligne la plus reconnue au monde, permettant aux utilisateurs de gagner une commission favorable pour chaque réservation effectuée par son intermédiaire.",
    "立即预订": "Réserver maintenant",
    "搜索": "Rechercher",
    "快乐的客户": "Client heureux",
    "快乐的酒店老板": "Propriétaire d'hôtel heureux",
    "评论1": "Plateforme incroyable avec de nombreuses fonctionnalités, vraiment digne d'être explorée!",
    "评论2": "Service clientèle proactif qui vous guidera si vous avez des doutes sur la plateforme.",
    "评论3": "Plateforme fiable, ça vaut le coup d'essayer!",
    "评论4": "J'ai réussi à lister mon hôtel ici et obtenir de bons résultats marketing.",
    "推荐计划1": "Les utilisateurs de la plateforme peuvent inviter d'autres personnes à devenir agents de la plateforme grâce à des codes de parrainage et devenir votre descendance.",
    "推荐计划2": "En tant qu'ascendant, vous pouvez extraire un certain pourcentage des bénéfices de votre descendance, et les bénéfices obtenus par l'ascendant seront directement retournés au compte de la plateforme de l'ascendant ou au rapport d'équipe.",
    "推荐计划3": "Remarque : Tous les agents / descendants de la plateforme recevront un certain pourcentage des bénéfices et des récompenses de la plateforme en conséquence, développer une équipe n'affecte pas les bénéfices et les récompenses de tout agent ou descendant.",
    "评分": "Note",
    "评论": "Commentaire",
    "酒店很棒": "L'hôtel est génial",
    "终于体验了，酒店非常好": "Enfin expérimenté, l'hôtel est très bon",
    "我对酒店非常满意并推荐预订": "Je suis très satisfait de l'hôtel et recommande la réservation",
    "我很高兴很满意，价格实惠": "Je suis très content et satisfait, le prix est abordable",
    "服务非常好，我非常喜欢": "Le service est très bon, j'aime beaucoup",
    "体验很不错，朋友已经第二次预订了": "L'expérience était très bonne, mon ami a déjà réservé pour la deuxième fois",
    "酒店环境优美": "Environnement de l'hôtel magnifique",
    "酒店卫生干净": "L'hôtel est hygiénique et propre",
    "工作人员态度非常好": "L'attitude du personnel est très bonne",
    "酒店服务很棒": "Le service de l'hôtel est excellent",
    "你还没有设置支付密码，请前往设置": "Vous n'avez pas encore défini de mot de passe de paiement, veuillez aller dans les paramètres",
    "订单": "Commandes",
    "复制成功": "Copie réussie",
    "可用订房余额": "Solde de réservation disponible",
    "获得佣金": "obtenir une commission",
    "历史总佣金": "Commission totale historique",
    "手机号码错误": "Numéro de téléphone incorrect",

}