export default {
    "title": "🇫🇮 Suomalainen",

    "首页": "Koti",
    "关于": "Tietoja",
    "会员": "Jäsenyys",
    "推荐计划": "Suositteluohjelma",
    "个人": "Profiili",
    "推荐": "Suositus",
    "预订": "Varaukset",
    "事件": "Tapahtuma",
    "登出": "Kirjaudu ulos",
    "登录": "Kirjaudu sisään",
    "注册": "Rekisteröidy",
    "我们的应用程序现在可用": "Sovelluksemme on nyt saatavilla",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Parempaa kokemusta varten olemme juuri julkaisseet mobiilisovelluksemme saumattomaan ja nopeampaan varaamiseen.",
    "iOS版下载（即将推出）": "Lataa iOS:lle (Tulossa pian)",
    "Android版下载（即将推出）": "Lataa Androidille (Tulossa pian)",
    "为什么选择我们": "Miksi valita meidät",
    "我们卓越的服务。": "Erinomaiset palvelumme.",
    "收入最高的酒店": "Parhaat tuottohotellit",
    "我们的合作酒店收入最高。": "Yhteistyökumppanihotellimme tuottavat eniten.",
    "回报": "Tuotot",
    "最具特色的酒店": "Erityisimmät hotellit",
    "我们平台上最有特色的酒店。": "Erityisimmät hotellit alustallamme.",
    "热门国家和目的地": "Suositut maat ja kohteet",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Tutustu parhaisiin vinkkeihin kaupunkien ympäri yhteistyökumppaneiltamme ja ystäviltämme.",
    "酒店": "Hotellit",
    "预订酒店并开始赚取": "Varaa hotelli ja ala ansaita",
    "让我们开始与TitanTraveler一起探索世界": "Aloitetaan maailman tutkiminen yhdessä TitanTravelerin kanssa",
    "客户评价": "Asiakaspalautteet",
    "查看我们用户的真实评价。": "Katso aidot käyttäjäarviomme.",
    "银行卡列表": "Pankkikorttilista",
    "银行列表": "Pankkilista",
    "持卡人姓名": "Kortinhaltijan nimi",
    "银行卡号": "Pankkitili",
    "支付密码": "Maksusalasana",
    "提交": "Lähetä",
    "Pix列表": "Pix-luettelo",
    "Pix类型": "Pix-tyyppi",
    "Pix账号": "Pix-tili",
    "账号列表": "Tilitiedot",
    "网络": "Verkko",
    "地址": "Osoite",
    "备注": "Huomautus",
    "充值金额": "Lataussumma",
    "支付类型": "Maksutapa",
    "支付银行": "Maksupankki",
    "USDT地址": "USDT-osoite",
    "二维码": "QR-koodi",
    "充值渠道": "Latauskanava",
    "充值凭证": "Latauskuitti",
    "立即登录": "Kirjaudu nyt",
    "用户名": "Käyttäjänimi",
    "密码": "Salasana",
    "邀请码": "Kutsukoodi",
    "确认密码": "Vahvista salasana",
    "手机": "Matkapuhelin",
    "注册2": "Rekisteröidy",
    "金额": "Summa",
    "新增": "Uusi",
    "我们的精彩": "Mahtavamme",
    "故事": "Tarina",
    "奖项": "Palkinnot",
    "获得": "Saatu",
    "钱包余额": "Lompakon saldo",
    "今日奖励": "Päivän palkinnot",
    "总行程": "Kokonaismatka",
    "访问行程": "Vieraillut matkat",
    "酒店历史": "Hotellihistoria",
    "单价": "Hinta",
    "数量": "Määrä",
    "总额": "Yhteensä",
    "奖励": "Palkinnot",
    "上一页": "Edellinen",
    "下一页": "Seuraava",
    "每套预订量": "Varaukset per setti",
    "预订奖励": "Varauspalkinto",
    "每日预订": "Päivittäiset varaukset",
    "手机号码": "Puhelinnumero",
    "修改密码": "Vaihda salasana",
    "登录密码": "Kirjautumissalasana",
    "当前密码": "Nykyinen salasana",
    "新密码": "Uusi salasana",
    "确认新密码": "Vahvista uusi salasana",
    "保存修改": "Tallenna muutokset",
    "充值": "Lataa",
    "我们的": "Meidän",
    "复制": "Kopioi",
    "分享链接": "Jaa linkki",
    "搜索酒店": "Etsi hotelli",
    "实际等级": "Todellinen taso",
    "升级": "Päivitä",
    "已完成预订": "Valmiit varaukset",
    "登录时间": "Kirjautumisaika",
    "信誉分": "Luotettavuus",
    "控制菜单": "Hallintapaneelin valikko",
    "提现": "Nosta",
    "提现账号": "Nostotili",
    "你确定要删除此账号吗": "Oletko varma, että haluat poistaa tämän tilin?",
    "你确定要删除此地址吗": "Oletko varma, että haluat poistaa tämän osoitteen?",
    "提示": "Vinkit",
    "是": "Kyllä",
    "否": "Ei",
    "全部": "Kaikki",
    "待处理": "Odottaa käsittelyä",
    "冻结中": "Jäädytetty",
    "已完成": "Valmis",
    "订阅": "Tilaa",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Haluatko saada ilmoituksen, kun julkaisemme uuden mallin tai päivityksen? Rekisteröidy, niin lähetämme sinulle ilmoituksen sähköpostitse.",
    "我们的伙伴": "Yhteistyökumppanimme",
    "关于我们": "MEISTÄ",
    "实时聊天支持": "Live Chat -tuki",
    "在线聊天": "Live Chat",
    "创建时间": "Luomisaika",
    "你确定要登出当前账号吗？": "Oletko varma, että haluat kirjautua ulos nykyiseltä tililtä?",
    "请输入你的邮箱": "Anna sähköpostiosoitteesi",
    "好评": "Hyvä",
    "条评论": "Arvostelut",
    "平均每晚": "Keskiarvo/yö",
    "旅行旅程": "Matkamatkat",
    "正在寻找酒店": "Etsii hotellia",
    "开始预订": "Aloita varaus",
    "旅程价格": "Matkan hinta",
    "佣金": "Komissiot",
    "底部-about": "on kumppanuusohjelma-alusta, joka tarjoaa ainutlaatuisen mahdollisuuden yhdistää liiketoimintasi maailman tunnetuimpaan verkkovarausalustaan, jonka avulla käyttäjät voivat ansaita suotuisaa komissiota jokaisesta tehdystä varauksesta.",
    "立即预订": "Varaa nyt",
    "搜索": "Haku",
    "快乐的客户": "Tyytyväiset asiakkaat",
    "快乐的酒店老板": "Tyytyväiset hotellinomistajat",
    "评论1": "Mahtava alusta, jossa on hienoja ominaisuuksia, todella tutustumisen arvoinen!",
    "评论2": "Aktiivinen asiakaspalvelu, joka auttaa sinua, jos olet epävarma alustasta.",
    "评论3": "Luotettava alusta, kokeilemisen arvoinen!",
    "评论4": "Onnistuin listaamaan hotellini tänne ja saavuttamaan erinomaisia markkinointituloksia.",
    "推荐计划1": "Alustan käyttäjät voivat kutsua muita tulemaan alustan edustajiksi suosittelukoodeilla ja heistä tulee alalinjaasi.",
    "推荐计划2": "Ylälinjana voit ottaa tietyn prosenttiosuuden alalinjasi voitosta, ja ylälinjan saama voitto palautetaan suoraan ylälinjan alustan tilille tai tiimiraporttiin.",
    "推荐计划3": "Huomautus: Kaikki alustan edustajat/alalinjat saavat tietyn prosenttiosuuden alustan voitoista ja palkinnoista. Tiimin kehittäminen ei vaikuta minkään edustajan tai alalinjojen voittoihin ja palkintoihin.",
    "评分": "Arvostelu",
    "评论": "Kommentti",
    "酒店很棒": "Hotelli on loistava",
    "终于体验了，酒店非常好": "Lopulta koin sen, hotelli on erittäin hyvä",
    "我对酒店非常满意并推荐预订": "Olen erittäin tyytyväinen hotelliin ja suosittelen varausta",
    "我很高兴很满意，价格实惠": "Olen hyvin onnellinen ja tyytyväinen, hinta on edullinen",
    "服务非常好，我非常喜欢": "Palvelu on erittäin hyvä, pidän siitä kovasti",
    "体验很不错，朋友已经第二次预订了": "Kokemus oli erittäin hyvä, ystäväni on jo varannut toisen kerran",
    "酒店环境优美": "Kaunis hotellin ympäristö",
    "酒店卫生干净": "Hotelli on hygieeninen ja puhdas",
    "工作人员态度非常好": "Henkilökunnan asenne on erittäin hyvä",
    "酒店服务很棒": "Hotellipalvelu on loistava",
    "你还没有设置支付密码，请前往设置": "Et ole asettanut maksusalasanaa, mene asetuksiin",
    "订单": "Tilaukset",
    "复制成功": "Kopiointi onnistui",
    "可用订房余额": "Saatavilla oleva varaussaldo",
    "获得佣金": "saa komissiota",
    "历史总佣金": "Historiallinen kokonaiskomissio",
    "手机号码错误": "Virheellinen puhelinnumero",

}