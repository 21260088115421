import axios from 'axios'
import i18n from '@/i18n'
import { authRef, memberLocal } from '@/model/user'
import $loading from '@/components/WiLoading'
import { toast } from '@/components/WiToast'

const MERCHANT_ID = process.env.VUE_APP_MERCHANT_ID

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.timeout = 30000

axios.interceptors.request.use(config => {
	config.headers['Accept-Language'] = i18n.global.locale
	config.headers['merchant-id'] = MERCHANT_ID
	if(authRef.value&&authRef.value.access_token) {
		config.headers['x-api-key'] = authRef.value.access_token
	}
	return config;
})

axios.interceptors.response.use(
	response => {
		if (response.data.code == 200) {
			return response.data;
		} else {
			if(response.data.code == 401) {
				authRef.value = {}
				memberLocal.value = {}
			}
			return Promise.reject(response.data)
		}
	},
	() => {
		var data = {
			code: 500,
			message: 'No Network Connection, Please Try Later'
		}
		return Promise.reject(data)
	}
)

const _request = async (
	options = { url: '', method: '', params: [], data: [] },
	tips = { loading: false, toast: false }
) => {
	try {
		if(tips.loading) {
			$loading.show()
		}
		const res = await axios(options);
		if(tips.loading) {
			$loading.hide()
		}
		if(tips.toast) {
			toast({
				title: res.message,
				type: 'success'
			})
		}
		return res;
	} catch (err) {
		if(tips.loading) {
			$loading.hide()
		}
		if(tips.toast) {
			toast({
				title: err.message,
				type: 'error'
			})
		}
		return err;
	}
}

const get = (options, tips = {loading: false, toast: false}) => {
	return _request({method: 'GET', ...options}, tips)
}

const post = (options, tips = {loading: false, toast: false}) => {
	return _request({method: 'POST', ...options}, tips)
}

const put = (options, tips = {loading: false, toast: false}) => {
	return _request({method: 'PUT', ...options}, tips)
}

const del = (options, tips = {loading: false, toast: false}) => {
	return _request({method: 'DELETE', ...options}, tips)
}

export { get, post, put, del}