export default {
    "title": "🇸🇪 svenska",

    "首页": "Hem",
    "关于": "Om",
    "会员": "Medlemskap",
    "推荐计划": "Rekommendationsprogram",
    "个人": "Profil",
    "推荐": "Rekommendation",
    "预订": "Bokningar",
    "事件": "Evenemang",
    "登出": "Logga ut",
    "登录": "Logga in",
    "注册": "Registrera",
    "我们的应用程序现在可用": "Vår app är tillgänglig nu",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "För en bättre upplevelse har vi precis lanserat våra mobilappar för sömlös och snabbare bokning.",
    "iOS版下载（即将推出）": "Ladda ner för iOS (Kommer snart)",
    "Android版下载（即将推出）": "Ladda ner för Android (Kommer snart)",
    "为什么选择我们": "Varför välja oss",
    "我们卓越的服务。": "Våra utsökta tjänster.",
    "收入最高的酒店": "Hotell med högsta intäkter",
    "我们的合作酒店收入最高。": "Våra samarbetshotell med högsta intäkter.",
    "回报": "Avkastning",
    "最具特色的酒店": "Mest framträdande hotell",
    "我们平台上最有特色的酒店。": "De mest utmärkta hotellen på vår plattform.",
    "热门国家和目的地": "Toppdestinationer och länder",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Utforska några av de bästa tipsen från runt staden från våra partners och vänner.",
    "酒店": "Hotell",
    "预订酒店并开始赚取": "Boka hotell och börja tjäna",
    "让我们开始与TitanTraveler一起探索世界": "Låt oss börja utforska världen tillsammans med TitanTraveler",
    "客户评价": "Omdömen",
    "查看我们用户的真实评价。": "Kolla våra autentiska omdömen från våra användare.",
    "银行卡列表": "Bankkortlista",
    "银行列表": "Banklista",
    "持卡人姓名": "Kortinnehavarens namn",
    "银行卡号": "Bankkontonummer",
    "支付密码": "Betalningslösenord",
    "提交": "Skicka in",
    "Pix列表": "Pix-lista",
    "Pix类型": "Pix-typ",
    "Pix账号": "Pix-konto",
    "账号列表": "Kontolista",
    "网络": "Nätverk",
    "地址": "Adress",
    "备注": "Notering",
    "充值金额": "Uppladdningsbelopp",
    "支付类型": "Betalningstyp",
    "支付银行": "Betalbank",
    "USDT地址": "USDT-adress",
    "二维码": "QR-kod",
    "充值渠道": "Uppladdningskanal",
    "充值凭证": "Uppladdningsbevis",
    "立即登录": "Logga in nu",
    "用户名": "Användarnamn",
    "密码": "Lösenord",
    "邀请码": "Inbjudningskod",
    "确认密码": "Bekräfta lösenordet",
    "手机": "Mobil",
    "注册2": "Registrera",
    "金额": "Belopp",
    "新增": "Ny",
    "我们的精彩": "Vårt fantastiska",
    "故事": "Berättelse",
    "奖项": "Utmärkelser",
    "获得": "Fått",
    "钱包余额": "Plånbokssaldo",
    "今日奖励": "Dagens belöningar",
    "总行程": "Totala resor",
    "访问行程": "Besökta resor",
    "酒店历史": "Hotellhistorik",
    "单价": "Pris",
    "数量": "Antal",
    "总额": "Total",
    "奖励": "Belöningar",
    "上一页": "Föregående",
    "下一页": "Nästa",
    "每套预订量": "Bokningar per set",
    "预订奖励": "Bokningsbelöning",
    "每日预订": "Dagliga bokningar",
    "手机号码": "Telefonnummer",
    "修改密码": "Ändra lösenord",
    "登录密码": "Inloggningslösenord",
    "当前密码": "Nuvarande lösenord",
    "新密码": "Nytt lösenord",
    "确认新密码": "Bekräfta nytt lösenord",
    "保存修改": "Spara ändringar",
    "充值": "Ladda upp",
    "我们的": "Vår",
    "复制": "Kopiera",
    "分享链接": "Dela länk",
    "搜索酒店": "Sök hotell",
    "实际等级": "Verklig nivå",
    "升级": "Uppgradera",
    "已完成预订": "Slutförda bokningar",
    "登录时间": "Inloggningstid",
    "信誉分": "Trovärdighet",
    "控制菜单": "Kontrollmeny",
    "提现": "Ta ut",
    "提现账号": "Uttagskonto",
    "你确定要删除此账号吗": "Är du säker på att du vill ta bort detta konto?",
    "你确定要删除此地址吗": "Är du säker på att du vill ta bort denna adress?",
    "提示": "Tips",
    "是": "Ja",
    "否": "Nej",
    "全部": "Alla",
    "待处理": "Pågående",
    "冻结中": "Fryst",
    "已完成": "Avslutad",
    "订阅": "Prenumerera",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Vill du bli meddelad när vi lanserar en ny mall eller uppdatering? Registrera dig bara så skickar vi ett meddelande via e-post.",
    "我们的伙伴": "Våra partners",
    "关于我们": "OM OSS",
    "实时聊天支持": "Live Chat Support",
    "在线聊天": "Livechatt",
    "创建时间": "Skapad tid",
    "你确定要登出当前账号吗？": "Är du säker på att du vill logga ut från det aktuella kontot?",
    "请输入你的邮箱": "Ange din e-post",
    "好评": "Mycket bra",
    "条评论": "Recensioner",
    "平均每晚": "Genomsnitt/natt",
    "旅行旅程": "Resor",
    "正在寻找酒店": "Letar efter hotell",
    "开始预订": "Börja boka",
    "旅程价格": "Resepris",
    "佣金": "Provisioner",
    "底部-about": "är en affiliate-programplattform som ger dig den unika möjligheten att koppla ditt företag till världens mest erkända onlinebokningsplattform för boende, vilket tillåter användare att tjäna en förmånlig provision för varje bokning som görs genom den.",
    "立即预订": "Boka nu",
    "搜索": "Sök",
    "快乐的客户": "Glada kunder",
    "快乐的酒店老板": "Glada hotellägare",
    "评论1": "Fantastisk plattform med bra funktioner, verkligen värt att utforska!",
    "评论2": "Proaktiv kundservice som guidar dig om du är osäker på plattformen.",
    "评论3": "Pålitlig plattform, värd att prova!",
    "评论4": "Lyckades lista mitt hotell här och uppnå bra marknadsföringsresultat.",
    "推荐计划1": "Plattformsanvändare kan bjuda in andra att bli plattformsagenter genom referenskoder och bli din nedlinje.",
    "推荐计划2": "Som överlinje kan du ta ut en viss procentandel av din nedlinjes vinst, och den vinst som erhålls av överlinjen kommer direkt tillbaka till överlinjens plattforms konto, eller till teamrapporten.",
    "推荐计划3": "Obs: Alla agenter/nedlinjer på plattformen kommer att få en viss procentandel av plattformens vinster och belöningar enligt detta, att utveckla ett team påverkar inte vinsterna och belöningarna för någon agent eller nedlinje.",
    "评分": "Betyg",
    "评论": "Kommentar",
    "酒店很棒": "Hotellet är fantastiskt",
    "终于体验了，酒店非常好": "Äntligen upplevt det, hotellet är mycket bra",
    "我对酒店非常满意并推荐预订": "Jag är mycket nöjd med hotellet och rekommenderar att boka",
    "我很高兴很满意，价格实惠": "Jag är mycket glad och nöjd, priset är överkomligt",
    "服务非常好，我非常喜欢": "Servicen är mycket bra, jag gillar den verkligen",
    "体验很不错，朋友已经第二次预订了": "Upplevelsen var mycket bra, min vän har redan bokat för andra gången",
    "酒店环境优美": "Vacker hotellägenhet",
    "酒店卫生干净": "Hotellet är hygieniskt och rent",
    "工作人员态度非常好": "Personalen har mycket bra attityd",
    "酒店服务很棒": "Hotelltjänsten är fantastisk",
    "你还没有设置支付密码，请前往设置": "Du har inte ställt in ett betalningslösenord, gå till inställningarna",
    "订单": "Beställningar",
    "复制成功": "Kopiering lyckades",
    "可用订房余额": "Tillgängligt reservationssaldo",
    "获得佣金": "få provision",
    "历史总佣金": "Historisk total provision",
    "手机号码错误": "Fel telefonnummer",

}