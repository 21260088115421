import { useDateFormat } from '@vueuse/core'

export const classList = (element,className,event) => {
    var isClsList = 'classList' in HTMLElement.prototype;
    if(!isClsList) {
        Object.defineProperty(HTMLElement.prototype,'classList',{
            get:function(){
                var _self = this;
                return {
                    add:function(cls){
                        if(!this.contains(cls)){
                            _self.className +=' ' + cls;
                        }
                    },
                    remove(cls){
                        if(this.contains(cls)){ 
                            var reg= new RegExp(cls);   
                            _self.className =  _self.className.replace(reg,'');
                        }
                    },
                    contains(cls){      
                    var index =  _self.className.indexOf(cls);
                        return  index!=-1 ? true : false;
                    },
                    toggle(cls){
                        if(this.contains(cls)){ 
                            this.remove(cls)
                        } else {
                            this.add(cls)
                        }
                    }
                }
            }
        })
    }
    if(event=='add') {
        element.classList.add(className);
    }else if(event=='remove') {
        element.classList.remove(className);
    }else if(event=='contains') {
        return element.classList.contains(className);
    } else {
        element.classList.toggle(className);
    }
}

export function resetHtml(con) {
    if(!con) return;
    return con.replace(/<img/g, '<img style="max-width:100% !important;"');
}

export function resetTime(timestring) {
    return useDateFormat(new Date(timestring * 1000), 'YYYY-MM-DD HH:mm A').value
}

export function scrollToTop() {
    const fromTopDistance = document.documentElement.scrollTop || document.body.scrollTop
    if (fromTopDistance > 0) {
        window.requestAnimationFrame(scrollToTop)
        window.scrollTo(0, fromTopDistance - fromTopDistance/ 8)
    }
}