export default {
    "title": "🇸🇦 عربي",

    "首页": "الرئيسية",
    "关于": "حول",
    "会员": "العضوية",
    "推荐计划": "برنامج الإحالة",
    "个人": "الملف الشخصي",
    "推荐": "الإحالة",
    "预订": "الحجوزات",
    "事件": "الفعالية",
    "登出": "تسجيل خروج",
    "登录": "تسجيل دخول",
    "注册": "تسجيل",
    "我们的应用程序现在可用": "تطبيقنا متاح الآن",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "لتحقيق تجربة أفضل، قمنا بإطلاق تطبيق الهاتف المحمول للحجز السلس والسريع.",
    "iOS版下载（即将推出）": "تنزيل لنظام iOS (قريبًا)",
    "Android版下载（即将推出）": "تنزيل لنظام Android(قريبًا)",
    "为什么选择我们": "لماذا تختارنا",
    "我们卓越的服务。": "خدماتنا المتميزة.",
    "收入最高的酒店": "الفنادق الأعلى دخلاً",
    "我们的合作酒店收入最高。": "فنادق التعاون لدينا الأعلى دخلاً.",
    "回报": "عوائد",
    "最具特色的酒店": "الفنادق الأكثر تميزاً",
    "我们平台上最有特色的酒店。": "الفنادق الأكثر تميزاً على منصتنا.",
    "热门国家和目的地": "الدول والوجهات الشهيرة",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "استكشف بعض أفضل النصائح من شركائنا وأصدقائنا حول المدينة.",
    "酒店": "الفنادق",
    "预订酒店并开始赚取": "احجز فندق وابدأ في الكسب",
    "让我们开始与TitanTraveler一起探索世界": "لنبدأ استكشاف العالم مع TitanTraveler",
    "客户评价": "آراء العملاء",
    "查看我们用户的真实评价。": "تحقق من آراء عملائنا الحقيقية.",
    "银行卡列表": "قائمة البطاقات البنكية",
    "银行列表": "قائمة البنوك",
    "持卡人姓名": "اسم حامل البطاقة",
    "银行卡号": "رقم الحساب البنكي",
    "支付密码": "رمز الدفع",
    "提交": "إرسال",
    "Pix列表": "قائمة Pix",
    "Pix类型": "نوع Pix",
    "Pix账号": "حساب Pix",
    "账号列表": "قائمة الحسابات",
    "网络": "الشبكة",
    "地址": "العنوان",
    "备注": "ملاحظة",
    "充值金额": "مبلغ الشحن",
    "支付类型": "نوع الدفع",
    "支付银行": "البنك المرسل",
    "USDT地址": "عنوان USDT",
    "二维码": "رمز الاستجابة السريعة",
    "充值渠道": "قناة الشحن",
    "充值凭证": "إيصال الشحن",
    "立即登录": "تسجيل الدخول الآن",
    "用户名": "اسم المستخدم",
    "密码": "كلمة المرور",
    "邀请码": "رمز الدعوة",
    "确认密码": "تأكيد كلمة المرور",
    "手机": "الهاتف المحمول",
    "注册2": "اشتراك",
    "金额": "المبلغ",
    "新增": "إضافة جديدة",
    "我们的精彩": "رائعنا",
    "故事": "القصة",
    "奖项": "الجوائز",
    "获得": "تم الحصول عليها",
    "钱包余额": "رصيد المحفظة",
    "今日奖励": "مكافآت اليوم",
    "总行程": "إجمالي الرحلات",
    "访问行程": "الرحلات التي تمت زيارتها",
    "酒店历史": "تاريخ الفندق",
    "单价": "السعر",
    "数量": "العدد",
    "总额": "الإجمالي",
    "奖励": "المكافآت",
    "上一页": "الصفحة السابقة",
    "下一页": "الصفحة التالية",
    "每套预订量": "الحد الأدنى للحجوزات",
    "预订奖励": "مكافأة الحجز",
    "每日预订": "الحجز اليومي",
    "手机号码": "رقم الهاتف",
    "修改密码": "تغيير كلمة المرور",
    "登录密码": "كلمة مرور الدخول",
    "当前密码": "كلمة المرور الحالية",
    "新密码": "كلمة المرور الجديدة",
    "确认新密码": "تأكيد كلمة المرور الجديدة",
    "保存修改": "حفظ التغييرات",
    "充值": "شحن",
    "我们的": "خاصتنا",
    "复制": "نسخ",
    "分享链接": "مشاركة الرابط",
    "搜索酒店": "البحث عن الفنادق",
    "实际等级": "المستوى الفعلي",
    "升级": "ترقية",
    "已完成预订": "الحجوزات المنتهية",
    "登录时间": "وقت تسجيل الدخول",
    "信誉分": "السمعة",
    "控制菜单": "لوحة التحكم",
    "提现": "سحب",
    "提现账号": "حساب السحب",
    "你确定要删除此账号吗": "هل أنت متأكد من حذف هذا الحساب",
    "你确定要删除此地址吗": "هل أنت متأكد من حذف هذا العنوان",
    "提示": "نصائح",
    "是": "نعم",
    "否": "لا",
    "全部": "الكل",
    "待处理": "قيد الانتظار",
    "冻结中": "مجمد",
    "已完成": "مكتمل",
    "订阅": "اشترك",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "ترغب في تلقي إشعار عندما نطلق قالبًا جديدًا أو تحديثًا؟ فقط اشترك وسنرسل لك إشعارًا عبر البريد الإلكتروني.",
    "我们的伙伴": "شركاؤنا",
    "关于我们": "عنا",
    "实时聊天支持": "دعم الدردشة المباشرة",
    "在线聊天": "الدردشة المباشرة",
    "创建时间": "وقت الإنشاء",
    "你确定要登出当前账号吗？": "هل أنت متأكد أنك تريد تسجيل الخروج من الحساب الحالي؟",
    "请输入你的邮箱": "أدخل بريدك الإلكتروني",
    "好评": "جيد جداً",
    "条评论": "المراجعات",
    "平均每晚": "متوسط/ليلة",
    "旅行旅程": "رحلات السفر",
    "正在寻找酒店": "تبحث عن فندق",
    "开始预订": "ابدأ الحجز",
    "旅程价格": "سعر الرحلة",
    "佣金": "العمولات",
    "底部-about": "هي منصة برنامج تابع تتيح لك الفرصة الفريدة لربط عملك بأكثر منصات حجز الإقامة عبر الإنترنت شهرة في العالم والتي تتيح للمستخدم كسب عمولة مفضلة لكل حجز يتم من خلالها.",
    "立即预订": "احجز الآن",
    "搜索": "بحث",
    "快乐的客户": "عملاء سعداء",
    "快乐的酒店老板": "مالك الفندق السعيد",
    "评论1": "منصة مذهلة بميزات رائعة، تستحق الاستكشاف!",
    "评论2": "خدمة عملاء استباقية ترشدك إذا كنت غير واضح حول المنصة.",
    "评论3": "منصة موثوقة، تستحق التجربة!",
    "评论4": "تمكنت من إدراج فندقي هنا وحققت نتائج تسويقية رائعة.",
    "推荐计划1": "يمكن لمستخدمي المنصة دعوة الآخرين ليصبحوا وكلاء للمنصة من خلال أكواد الإحالة ويصبحوا خطوطك الأمامية.",
    "推荐计划2": "بصفتك الخط العلوي، يمكنك استخراج نسبة معينة من أرباح خطوطك السفلية، وستعود الأرباح التي يحصل عليها الخط العلوي مباشرة إلى حساب الخط العلوي على المنصة، أو إلى تقرير الفريق.",
    "推荐计划3": "ملاحظة: جميع وكلاء المنصة / الخطوط السفلية سيحصلون على نسبة معينة من أرباح المنصة والمكافآت وفقًا لذلك، فإن تطوير فريق لا يؤثر على أرباح ومكافآت أي وكيل أو خط سفلي.",
    "评分": "التقييم",
    "评论": "تعليق",
    "酒店很棒": "الفندق رائع",
    "终于体验了，酒店非常好": "أخيراً جربته، الفندق جيد جداً",
    "我对酒店非常满意并推荐预订": "أنا راضٍ جداً عن الفندق وأوصي بالحجز",
    "我很高兴很满意，价格实惠": "أنا سعيد جداً وراضٍ، السعر معقول",
    "服务非常好，我非常喜欢": "الخدمة جيدة جداً، أنا أحبها كثيراً",
    "体验很不错，朋友已经第二次预订了": "كانت التجربة جيدة جداً، وقد حجز صديقي بالفعل للمرة الثانية",
    "酒店环境优美": "بيئة الفندق جميلة",
    "酒店卫生干净": "الفندق نظيف وصحي",
    "工作人员态度非常好": "موقف الموظفين جيد جداً",
    "酒店服务很棒": "خدمة الفندق رائعة",
    "你还没有设置支付密码，请前往设置": "لم تقم بعد بتعيين كلمة مرور الدفع، يرجى التوجه إلى الإعدادات",
    "订单": "الطلبات",
    "复制成功": "نسخ بنجاح",
    "可用订房余额": "رصيد الحجز المتاح",
    "获得佣金": "الحصول على عمولة",
    "历史总佣金": "إجمالي العمولات التاريخية",
    "手机号码错误": "رقم الهاتف غير صحيح",

}