export default {
    "title": "🇩🇪 Deutsch",

    "首页": "Startseite",
    "关于": "Über",
    "会员": "Mitgliedschaft",
    "推荐计划": "Empfehlungsprogramm",
    "个人": "Profil",
    "推荐": "Empfehlung",
    "预订": "Buchungen",
    "事件": "Veranstaltung",
    "登出": "Abmelden",
    "登录": "Anmelden",
    "注册": "Registrieren",
    "我们的应用程序现在可用": "Unsere App ist jetzt verfügbar",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Für ein besseres Erlebnis haben wir gerade unsere mobilen Apps für nahtloses und schnelleres Buchen vorgestellt.",
    "iOS版下载（即将推出）": "Download für iOS (Demnächst)",
    "Android版下载（即将推出）": "Download für Android (Demnächst)",
    "为什么选择我们": "Warum uns wählen",
    "我们卓越的服务。": "Unser herausragender Service.",
    "收入最高的酒店": "Höchste Einnahmen Hotels",
    "我们的合作酒店收入最高。": "Unsere Partnerhotels mit den höchsten Einnahmen.",
    "回报": "Renditen",
    "最具特色的酒店": "Besondere Hotels",
    "我们平台上最有特色的酒店。": "Die besonders hervorzuhebenden Hotels auf unserer Plattform.",
    "热门国家和目的地": "Beliebte Länder & Reiseziele",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Entdecken Sie einige der besten Tipps aus der Stadt von unseren Partnern und Freunden.",
    "酒店": "Hotels",
    "预订酒店并开始赚取": "Hotel buchen & Geld verdienen",
    "让我们开始与TitanTraveler一起探索世界": "Lassen Sie uns gemeinsam mit TitanTraveler die Welt erkunden",
    "客户评价": "Kundenbewertungen",
    "查看我们用户的真实评价。": "Sehen Sie sich authentische Bewertungen unserer Nutzer an.",
    "银行卡列表": "Bankkartenliste",
    "银行列表": "Bankenliste",
    "持卡人姓名": "Name des Karteninhabers",
    "银行卡号": "Bankkonto",
    "支付密码": "Bezahlpasswort",
    "提交": "Einreichen",
    "Pix列表": "Pix-Liste",
    "Pix类型": "Pix-Typ",
    "Pix账号": "Pix-Konto",
    "账号列表": "Kontoliste",
    "网络": "Netzwerk",
    "地址": "Adresse",
    "备注": "Hinweis",
    "充值金额": "Aufladebetrag",
    "支付类型": "Zahlungsmethode",
    "支付银行": "Zahlungsbank",
    "USDT地址": "USDT-Adresse",
    "二维码": "QR-Code",
    "充值渠道": "Aufladekanal",
    "充值凭证": "Aufladebeleg",
    "立即登录": "Jetzt anmelden",
    "用户名": "Benutzername",
    "密码": "Passwort",
    "邀请码": "Einladungscode",
    "确认密码": "Passwort bestätigen",
    "手机": "Mobiltelefon",
    "注册2": "Anmelden",
    "金额": "Betrag",
    "新增": "Neu",
    "我们的精彩": "Unser Großartiges",
    "故事": "Geschichte",
    "奖项": "Auszeichnungen",
    "获得": "Erhalten",
    "钱包余额": "Geldbörsen-Guthaben",
    "今日奖励": "Heutige Belohnungen",
    "总行程": "Gesamtreise",
    "访问行程": "Besuchte Reisen",
    "酒店历史": "Hotellgeschichte",
    "单价": "Preis",
    "数量": "Menge",
    "总额": "Gesamt",
    "奖励": "Belohnungen",
    "上一页": "Vorherige",
    "下一页": "Nächste",
    "每套预订量": "Buchungen pro Set",
    "预订奖励": "Buchungsbelohnung",
    "每日预订": "Tägliche Buchungen",
    "手机号码": "Telefonnummer",
    "修改密码": "Passwort ändern",
    "登录密码": "Anmelde-Passwort",
    "当前密码": "Aktuelles Passwort",
    "新密码": "Neues Passwort",
    "确认新密码": "Neues Passwort bestätigen",
    "保存修改": "Änderungen speichern",
    "充值": "Aufladen",
    "我们的": "Unser",
    "复制": "Kopieren",
    "分享链接": "Link teilen",
    "搜索酒店": "Hotel suchen",
    "实际等级": "Aktuelles Level",
    "升级": "Upgrade",
    "已完成预订": "Abgeschlossene Buchungen",
    "登录时间": "Anmeldezeit",
    "信誉分": "Glaubwürdigkeit",
    "控制菜单": "Dashboard-Menü",
    "提现": "Abheben",
    "提现账号": "Auszahlungskonto",
    "你确定要删除此账号吗": "Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
    "你确定要删除此地址吗": "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
    "提示": "Tipps",
    "是": "Ja",
    "否": "Nein",
    "全部": "Alle",
    "待处理": "Ausstehend",
    "冻结中": "Eingefroren",
    "已完成": "Abgeschlossen",
    "订阅": "Abonnieren",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Möchten Sie benachrichtigt werden, wenn wir eine neue Vorlage oder ein Update veröffentlichen? Melden Sie sich einfach an und wir senden Ihnen eine Benachrichtigung per E-Mail.",
    "我们的伙伴": "Unsere Partner",
    "关于我们": "ÜBER UNS",
    "实时聊天支持": "Live-Chat-Support",
    "在线聊天": "Live-Chat",
    "创建时间": "Erstellungszeit",
    "你确定要登出当前账号吗？": "Sind Sie sicher, dass Sie das aktuelle Konto abmelden möchten?",
    "请输入你的邮箱": "Geben Sie Ihre E-Mail ein",
    "好评": "Sehr gut",
    "条评论": "Bewertungen",
    "平均每晚": "Durchschn./Nacht",
    "旅行旅程": "Reisen",
    "正在寻找酒店": "Suche nach einem Hotel",
    "开始预订": "Mit dem Buchen beginnen",
    "旅程价格": "Reisepreis",
    "佣金": "Provisionen",
    "底部-about": "ist eine Affiliate-Programm-Plattform, die Ihnen die einzigartige Möglichkeit bietet, Ihr Geschäft mit der weltweit bekanntesten Online-Buchungsplattform für Unterkünfte zu verbinden und eine vorteilhafte Provision für jede über sie getätigte Buchung zu verdienen.",
    "立即预订": "Jetzt buchen",
    "搜索": "Suchen",
    "快乐的客户": "Glückliche Kunden",
    "快乐的酒店老板": "Glückliche Hotelbesitzer",
    "评论1": "Erstaunliche Plattform mit großartigen Funktionen, wirklich einen Besuch wert!",
    "评论2": "Proaktiver Kundenservice, der Sie durchführt, wenn Sie sich über die Plattform nicht im Klaren sind.",
    "评论3": "Vertrauenswürdige Plattform, einen Versuch wert!",
    "评论4": "Ich habe es geschafft, mein Hotel hier aufzulisten und großartige Marketingergebnisse erzielt.",
    "推荐计划1": "Plattformbenutzer können andere einladen, Plattformagenten zu werden, indem sie Empfehlungscodes verwenden und Ihre Downline werden.",
    "推荐计划2": "Als Upline können Sie einen bestimmten Prozentsatz des Gewinns Ihrer Downline extrahieren, und der Gewinn, den die Upline erhält, wird direkt dem Plattformkonto der Upline oder dem Teambericht gutgeschrieben.",
    "推荐计划3": "Hinweis: Alle Agenten / Downlines der Plattform erhalten einen bestimmten Prozentsatz der Plattformgewinne und -belohnungen entsprechend. Die Entwicklung eines Teams beeinträchtigt nicht die Gewinne und Belohnungen eines Agenten oder einer Downline.",
    "评分": "Bewertung",
    "评论": "Kommentar",
    "酒店很棒": "Das Hotel ist großartig",
    "终于体验了，酒店非常好": "Endlich erlebt, das Hotel ist sehr gut",
    "我对酒店非常满意并推荐预订": "Ich bin sehr zufrieden mit dem Hotel und empfehle es weiter",
    "我很高兴很满意，价格实惠": "Ich bin sehr glücklich und zufrieden, der Preis ist erschwinglich",
    "服务非常好，我非常喜欢": "Der Service ist sehr gut, ich mag ihn sehr",
    "体验很不错，朋友已经第二次预订了": "Die Erfahrung war sehr gut, mein Freund hat bereits zum zweiten Mal gebucht",
    "酒店环境优美": "Schöne Hotelumgebung",
    "酒店卫生干净": "Das Hotel ist hygienisch und sauber",
    "工作人员态度非常好": "Das Personal hat eine sehr gute Einstellung",
    "酒店服务很棒": "Der Hotelservice ist großartig",
    "你还没有设置支付密码，请前往设置": "Sie haben noch kein Zahlungspasswort festgelegt, bitte gehen Sie zu den Einstellungen",
    "订单": "Bestellungen",
    "复制成功": "Erfolgreich kopiert",
    "可用订房余额": "Verfügbares Reservierungsguthaben",
    "获得佣金": "Provision erhalten",
    "历史总佣金": "Historische Gesamtsumme der Provisionen",
    "手机号码错误": "Falsche Telefonnummer",

}