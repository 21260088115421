export default {
    "title": "🇭🇺 magyar",

    "首页": "Főoldal",
    "关于": "Rólunk",
    "会员": "Tagság",
    "推荐计划": "Ajánlói Program",
    "个人": "Profil",
    "推荐": "Ajánlás",
    "预订": "Foglalások",
    "事件": "Esemény",
    "登出": "Kijelentkezés",
    "登录": "Bejelentkezés",
    "注册": "Regisztráció",
    "我们的应用程序现在可用": "Alkalmazásunk most elérhető",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "A jobb élmény érdekében most indítottuk el mobilalkalmazásainkat a zökkenőmentesebb és gyorsabb foglalás érdekében.",
    "iOS版下载（即将推出）": "Letöltés iOS-re (hamarosan)",
    "Android版下载（即将推出）": "Letöltés Androidra (hamarosan)",
    "为什么选择我们": "Miért válasszon minket",
    "我们卓越的服务。": "Kiemelkedő szolgáltatásaink.",
    "收入最高的酒店": "Legnagyobb bevételt hozó hotelek",
    "我们的合作酒店收入最高。": "Partnereink közül ezek a hotelek generálják a legnagyobb bevételt.",
    "回报": "Nyereség",
    "最具特色的酒店": "Legkiemelkedőbb hotelek",
    "我们平台上最有特色的酒店。": "A platformunkon található legkiemelkedőbb hotelek.",
    "热门国家和目的地": "Legnépszerűbb országok és úti célok",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Fedezze fel partnereink és barátaink legjobb tippjeit a város különböző részeiről.",
    "酒店": "Hotelek",
    "预订酒店并开始赚取": "Foglaljon szállodát és kezdjen el keresni",
    "让我们开始与TitanTraveler一起探索世界": "Kezdjük el együtt felfedezni a világot a TitanTraveler-rel",
    "客户评价": "Vélemények",
    "查看我们用户的真实评价。": "Tekintse meg felhasználóink valós véleményeit.",
    "银行卡列表": "Bankkártya lista",
    "银行列表": "Bank lista",
    "持卡人姓名": "Kártyabirtokos neve",
    "银行卡号": "Bankkártya szám",
    "支付密码": "Fizetési jelszó",
    "提交": "Beküldés",
    "Pix列表": "Pix lista",
    "Pix类型": "Pix típusa",
    "Pix账号": "Pix számla",
    "账号列表": "Számla lista",
    "网络": "Hálózat",
    "地址": "Cím",
    "备注": "Megjegyzés",
    "充值金额": "Feltöltési összeg",
    "支付类型": "Fizetési típus",
    "支付银行": "Fizető bank",
    "USDT地址": "USDT cím",
    "二维码": "QR kód",
    "充值渠道": "Feltöltési csatorna",
    "充值凭证": "Feltöltési nyugta",
    "立即登录": "Bejelentkezés most",
    "用户名": "Felhasználónév",
    "密码": "Jelszó",
    "邀请码": "Meghívókód",
    "确认密码": "Jelszó megerősítése",
    "手机": "Mobil",
    "注册2": "Regisztráció",
    "金额": "Összeg",
    "新增": "Új",
    "我们的精彩": "Csodálatos",
    "故事": "Történetünk",
    "奖项": "Díjak",
    "获得": "Elért",
    "钱包余额": "Pénztárca egyenleg",
    "今日奖励": "Mai jutalmak",
    "总行程": "Összes utazás",
    "访问行程": "Látogatott utazások",
    "酒店历史": "Hotel történet",
    "单价": "Egységár",
    "数量": "Darabszám",
    "总额": "Összesen",
    "奖励": "Jutalmak",
    "上一页": "Előző",
    "下一页": "Következő",
    "每套预订量": "Foglalások készletenként",
    "预订奖励": "Foglalási jutalom",
    "每日预订": "Napi foglalás",
    "手机号码": "Telefonszám",
    "修改密码": "Jelszó módosítása",
    "登录密码": "Bejelentkezési jelszó",
    "当前密码": "Jelenlegi jelszó",
    "新密码": "Új jelszó",
    "确认新密码": "Új jelszó megerősítése",
    "保存修改": "Módosítás mentése",
    "充值": "Feltöltés",
    "我们的": "Mi",
    "复制": "Másolás",
    "分享链接": "Link megosztása",
    "搜索酒店": "Hotel keresése",
    "实际等级": "Valós szint",
    "升级": "Frissítés",
    "已完成预订": "Teljesített foglalások",
    "登录时间": "Bejelentkezési idő",
    "信誉分": "Hitelpontszám",
    "控制菜单": "Irányítópult menü",
    "提现": "Kivét",
    "提现账号": "Kivét számla",
    "你确定要删除此账号吗": "Biztosan törölni szeretné ezt a fiókot?",
    "你确定要删除此地址吗": "Biztosan törölni szeretné ezt a címet?",
    "提示": "Tippek",
    "是": "Igen",
    "否": "Nem",
    "全部": "Összes",
    "待处理": "Feldolgozás alatt",
    "冻结中": "Befagyasztva",
    "已完成": "Befejezett",
    "订阅": "Feliratkozás",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Szeretne értesítést kapni, amikor új sablonokat vagy frissítéseket indítunk? Csak iratkozzon fel, és e-mailben küldünk értesítést.",
    "我们的伙伴": "Partnereink",
    "关于我们": "RÓLUNK",
    "实时聊天支持": "Élő chat támogatás",
    "在线聊天": "Élő chat",
    "创建时间": "Létrehozás ideje",
    "你确定要登出当前账号吗？": "Biztosan ki szeretne jelentkezni az aktuális fiókból?",
    "请输入你的邮箱": "Adja meg az e-mail címét",
    "好评": "Nagyon jó",
    "条评论": "Vélemények",
    "平均每晚": "Átlag/Éjszaka",
    "旅行旅程": "Utazások",
    "正在寻找酒店": "Szállodát keres",
    "开始预订": "Foglalás indítása",
    "旅程价格": "Utazási ár",
    "佣金": "Jutalékok",
    "底部-about": "egy affiliate program platform, amely egyedi lehetőséget biztosít arra, hogy vállalkozását összekapcsolja a világ legismertebb online szállásfoglalási platformjával, amely lehetővé teszi a felhasználók számára, hogy kedvező jutalékot keressenek minden foglalással.",
    "立即预订": "Foglalás most",
    "搜索": "Keresés",
    "快乐的客户": "Boldog ügyfelek",
    "快乐的酒店老板": "Boldog szállodatulajdonosok",
    "评论1": "Csodálatos platform nagyszerű funkciókkal, igazán érdemes felfedezni!",
    "评论2": "Proaktív ügyfélszolgálat, amely segít, ha nem világos a platform használata.",
    "评论3": "Megbízható platform, érdemes kipróbálni!",
    "评论4": "Sikerült listáznom a szállodámat itt és kiváló marketing eredményt értem el.",
    "推荐计划1": "A platform felhasználói meghívókódok segítségével másokat is meghívhatnak, hogy lépjenek be a platformra és legyenek az ő alsóvonaluk.",
    "推荐计划2": "Felsővonalként egy bizonyos százalékot levonhatsz az alsóvonalad nyereségéből, és a felsővonal által megszerzett nyereséget közvetlenül visszahelyezik a felsővonal platformfiókjába vagy a csapatjelentéshez.",
    "推荐计划3": "Megjegyzés: A platform összes ügynöke/alsóvonala a platform nyereségeinek és jutalmainak meghatározott százalékát kapja, a csapat fejlesztése nem befolyásolja bármely ügynök vagy alsóvonal nyereségét és jutalmait.",
    "评分": "Értékelés",
    "评论": "Megjegyzés",
    "酒店很棒": "A szálloda nagyszerű",
    "终于体验了，酒店非常好": "Végre kipróbáltam, a szálloda nagyon jó",
    "我对酒店非常满意并推荐预订": "Nagyon elégedett vagyok a szállodával és ajánlom a foglalást",
    "我很高兴很满意，价格实惠": "Nagyon boldog és elégedett vagyok, az ár kedvező",
    "服务非常好，我非常喜欢": "A szolgáltatás nagyon jó, nagyon tetszik",
    "体验很不错，朋友已经第二次预订了": "Az élmény nagyon jó volt, a barátom már másodszor foglalta le",
    "酒店环境优美": "Gyönyörű hotel környezet",
    "酒店卫生干净": "A szálloda higiénikus és tiszta",
    "工作人员态度非常好": "A személyzet hozzáállása nagyon jó",
    "酒店服务很棒": "A szálloda szolgáltatása nagyszerű",
    "你还没有设置支付密码，请前往设置": "Még nem állítottál be fizetési jelszót, kérlek menj a beállításokhoz",
    "订单": "Rendelések",
    "复制成功": "Másolás sikeres",
    "可用订房余额": "Elérhető foglalási egyenleg",
    "获得佣金": "Jutalék szerzése",
    "历史总佣金": "Összesített történelmi jutalék",
    "手机号码错误": "Hibás telefonszám",

}