import { createI18n } from 'vue-i18n'
import { useLocalStorage } from '@vueuse/core'
import zhHK from './language/zh-hk'
import en from './language/en'
import ar from './language/ar'
import de from './language/de'
import fi from './language/fi'
import fr from './language/fr'
import it from './language/it'
import sv from './language/sv'
import hu from './language/hu'


/**
 * Amount formatting
 */
export function formatAmountFunc(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


const langRef = useLocalStorage('language', 'en')

const messages = { en, ar, de, fi, fr, it, sv, hu } // 'zh-hk': zhHK

const i18n = createI18n({ locale: langRef.value, messages })

const languageList = Object.keys(messages).map(k =>
  ({ label: messages[k].title, value: k })
)

const setLanguage = (item = { label: '', value: '' }) => {
  i18n.locale = item.value
  langRef.value = item.value
  setTimeout(() => {
    location.reload()
  }, 500);
}

const t = i18n.global.t

const moneyFlag = process.env.VUE_APP_MONEY_FLAG

const fm = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return `${moneyFlag} ${_val}`
}

const fmt = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return `${_val} ${moneyFlag}`
}

const fn = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return _val
}

export default i18n
export { t, langRef, languageList, setLanguage, fm, fn, fmt }
