export default {
    "title": "🇮🇹 Italiano",

    "首页": "Home",
    "关于": "Chi Siamo",
    "会员": "Iscrizione",
    "推荐计划": "Programma di Referenza",
    "个人": "Profilo",
    "推荐": "Referenza",
    "预订": "Prenotazioni",
    "事件": "Evento",
    "登出": "Esci",
    "登录": "Accedi",
    "注册": "Registrati",
    "我们的应用程序现在可用": "La Nostra App è Ora Disponibile",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Per una migliore esperienza, abbiamo appena lanciato le nostre app mobili per una prenotazione più rapida e senza interruzioni.",
    "iOS版下载（即将推出）": "Scarica per iOS (Prossimamente)",
    "Android版下载（即将推出）": "Scarica per Android (Prossimamente)",
    "为什么选择我们": "Perché Sceglierci",
    "我们卓越的服务。": "I nostri servizi eccezionali.",
    "收入最高的酒店": "Hotel con Maggiori Guadagni",
    "我们的合作酒店收入最高。": "I nostri hotel in collaborazione hanno i guadagni più alti.",
    "回报": "Rendimenti",
    "最具特色的酒店": "Hotel Più Vistosi",
    "我们平台上最有特色的酒店。": "L'hotel più vistoso sulla nostra piattaforma.",
    "热门国家和目的地": "Paesi e Destinazioni Top",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Scopri alcuni dei migliori consigli sulla città dai nostri partner e amici.",
    "酒店": "Hotel",
    "预订酒店并开始赚取": "Prenota un Hotel e Inizia a Guadagnare",
    "让我们开始与TitanTraveler一起探索世界": "Iniziamo a esplorare il mondo insieme a TitanTraveler",
    "客户评价": "Testimonianze",
    "查看我们用户的真实评价。": "Controlla le testimonianze autentiche dei nostri utenti.",
    "银行卡列表": "Elenco Carte Bancarie",
    "银行列表": "Elenco Banche",
    "持卡人姓名": "Nome del Titolare della Carta",
    "银行卡号": "Numero di Conto Bancario",
    "支付密码": "Password di Pagamento",
    "提交": "Invia",
    "Pix列表": "Elenco Pix",
    "Pix类型": "Tipo di Pix",
    "Pix账号": "Numero di Conto Pix",
    "账号列表": "Elenco Conti",
    "网络": "Rete",
    "地址": "Indirizzo",
    "备注": "Nota",
    "充值金额": "Importo Ricarica",
    "支付类型": "Tipo di Pagamento",
    "支付银行": "Banca di Pagamento",
    "USDT地址": "Indirizzo USDT",
    "二维码": "Codice QR",
    "充值渠道": "Canale di Ricarica",
    "充值凭证": "Voucher di Ricarica",
    "立即登录": "Accedi Ora",
    "用户名": "Nome Utente",
    "密码": "Password",
    "邀请码": "Codice di Invito",
    "确认密码": "Conferma Password",
    "手机": "Cellulare",
    "注册2": "Iscriviti",
    "金额": "Importo",
    "新增": "Nuovo",
    "我们的精彩": "Il Nostro Fantastico",
    "故事": "Storia",
    "奖项": "Premi",
    "获得": "Ottenuto",
    "钱包余额": "Saldo Portafoglio",
    "今日奖励": "Ricompense di Oggi",
    "总行程": "Viaggio Totale",
    "访问行程": "Viaggi Visitati",
    "酒店历史": "Storico Hotel",
    "单价": "Prezzo",
    "数量": "Numero",
    "总额": "Totale",
    "奖励": "Ricompense",
    "上一页": "Prec",
    "下一页": "Succ",
    "每套预订量": "Prenotazioni per Set",
    "预订奖励": "Ricompensa Prenotazione",
    "每日预订": "Prenotazioni Giornaliere",
    "手机号码": "Numero di Telefono",
    "修改密码": "Cambia Password",
    "登录密码": "Password di Accesso",
    "当前密码": "Password Attuale",
    "新密码": "Nuova Password",
    "确认新密码": "Conferma Nuova Password",
    "保存修改": "Salva Modifiche",
    "充值": "Ricarica",
    "我们的": "Il Nostro",
    "复制": "Copia",
    "分享链接": "Condividi Link",
    "搜索酒店": "Cerca Hotel",
    "实际等级": "Livello Reale",
    "升级": "Aggiorna",
    "已完成预订": "Prenotazioni Completate",
    "登录时间": "Ora di Accesso",
    "信誉分": "Affidabilità",
    "控制菜单": "Menu Dashboard",
    "提现": "Preleva",
    "提现账号": "Conto di Prelievo",
    "你确定要删除此账号吗": "Sei sicuro di voler eliminare questo conto?",
    "你确定要删除此地址吗": "Sei sicuro di voler eliminare questo indirizzo?",
    "提示": "Suggerimenti",
    "是": "Sì",
    "否": "No",
    "全部": "Tutti",
    "待处理": "In Attesa",
    "冻结中": "Congelato",
    "已完成": "Completato",
    "订阅": "Iscriviti",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Vuoi essere avvisato quando lanciamo un nuovo modello o un aggiornamento? Iscriviti e ti invieremo una notifica via email.",
    "我们的伙伴": "I Nostri Partner",
    "关于我们": "CHI SIAMO",
    "实时聊天支持": "Supporto Chat Live",
    "在线聊天": "Chat Live",
    "创建时间": "Orario di Creazione",
    "你确定要登出当前账号吗？": "Sei sicuro di voler uscire dall'account corrente?",
    "请输入你的邮箱": "Inserisci la tua Email",
    "好评": "Molto Buono",
    "条评论": "Recensioni",
    "平均每晚": "Media/Notte",
    "旅行旅程": "Viaggi",
    "正在寻找酒店": "Cerco un hotel",
    "开始预订": "Inizia a Prenotare",
    "旅程价格": "Prezzo del Viaggio",
    "佣金": "Commissioni",
    "底部-about": "è una piattaforma di programma di affiliazione che ti offre l'opportunità unica di collegare la tua azienda alla piattaforma di prenotazione di alloggi online più riconosciuta al mondo, permettendo agli utenti di guadagnare una commissione favorevole per ogni prenotazione effettuata tramite essa.",
    "立即预订": "Prenota ora",
    "搜索": "Cerca",
    "快乐的客户": "Cliente Felice",
    "快乐的酒店老板": "Proprietario di Hotel Felice",
    "评论1": "Piattaforma straordinaria con ottime funzionalità, davvero vale la pena esplorarla!",
    "评论2": "Servizio clienti proattivo che ti guiderà se hai dubbi sulla piattaforma.",
    "评论3": "Piattaforma affidabile, vale la pena provare!",
    "评论4": "Sono riuscito a elencare il mio hotel qui e ottenere ottimi risultati di marketing.",
    "推荐计划1": "Gli utenti della piattaforma possono invitare altri a diventare agenti della piattaforma tramite codici di referenza e diventare tuoi downline.",
    "推荐计划2": "Come upline, puoi estrarre una certa percentuale del profitto dei tuoi downline, e il profitto ottenuto dall'upline sarà direttamente restituito all'account della piattaforma dell'upline, o al rapporto del team.",
    "推荐计划3": "Nota: Tutti gli agenti/downline della piattaforma riceveranno una certa percentuale dei profitti e delle ricompense della piattaforma di conseguenza, sviluppare un team non influisce sui profitti e sulle ricompense di nessun agente o downline.",
    "评分": "Valutazione",
    "评论": "Commento",
    "酒店很棒": "L'hotel è fantastico",
    "终于体验了，酒店非常好": "Finalmente provato, l'hotel è molto buono",
    "我对酒店非常满意并推荐预订": "Sono molto soddisfatto dell'hotel e consiglio la prenotazione",
    "我很高兴很满意，价格实惠": "Sono molto felice e soddisfatto, il prezzo è conveniente",
    "服务非常好，我非常喜欢": "Il servizio è molto buono, mi piace molto",
    "体验很不错，朋友已经第二次预订了": "L'esperienza è stata molto buona, il mio amico ha già prenotato per la seconda volta",
    "酒店环境优美": "Ambiente dell'hotel bellissimo",
    "酒店卫生干净": "L'hotel è igienico e pulito",
    "工作人员态度非常好": "L'atteggiamento del personale è molto buono",
    "酒店服务很棒": "Il servizio dell'hotel è fantastico",
    "你还没有设置支付密码，请前往设置": "Non hai impostato una password di pagamento, per favore vai alle impostazioni",
    "订单": "Ordini",
    "复制成功": "Copia riuscita",
    "可用订房余额": "Saldo disponibile per le prenotazioni",
    "获得佣金": "ottieni commissione",
    "历史总佣金": "Totale storico delle commissioni",
    "手机号码错误": "Numero di telefono errato",

}